import { Modal } from '@contact/ui-components';
import React from 'react';
import Logo from '../Assets/Images/contact-logo-arc.svg';
import './ErrorModal.scss';

type Props = {
  title: string;
  description: string;
  isOpen: boolean;
  handleClose: () => void;
};

export const ErrorModal = ({
  isOpen,
  handleClose,
  title,
  description,
}: Props) => {
  return (
    <Modal
      isActive={isOpen}
      title={title}
      content={
        <div className="formModal-container">
          <img src={Logo} className="formModal-image" />
          <p>{description}</p>
        </div>
      }
      handleClose={handleClose}
      titleClassName="trimester-modal-title"
      contentClassName="trimester-modal-content"
    />
  );
};
