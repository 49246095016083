import React, { useEffect } from 'react';
import { BaseProvider } from '@contact/react';
import { environment } from '../environments/environment';
import { Page, PageContent, Footer, Header } from '@contact/ui-components';
import { CCTheme, CcFee } from '@contact-react/ccfee';
import { ChakraProvider } from '@contact/ui-volta';
import { Gtm, injectGtm } from '../gtm';

const sx = {
  minHeight: '100vh',
  display: 'flex',
  flexDirection: 'column',
};

export function App() {
  useEffect(() => {
    injectGtm(Gtm.reactAppId);
  }, []);

  return (
    <ChakraProvider theme={CCTheme}>
      <BaseProvider config={environment}>
        <Page translationNamespace="ccfee" hasGlobalHeader={false}>
          <PageContent id sx={sx}>
            <Header />
            <CcFee />
            <Footer />
          </PageContent>
        </Page>
      </BaseProvider>
    </ChakraProvider>
  );
}

export default App;
