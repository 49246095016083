import React, { useState } from 'react';
import { Controller } from 'react-hook-form';
import {
  FormControl,
  FormLabel,
  FormErrorMessage,
  H4,
  Text,
  Box,
  HStack,
} from '@contact/ui-volta';
import { FormAlert } from '../CcFormComponents/FormAlert';
import { DatePicker, QuestionMarkIcon } from '@contact/ui-components';

export const FormInputDate = ({
  name,
  label,
  title,
  description = undefined,
  descriptionComponent: DescriptionComponent = undefined, // react component
  className = undefined,
  control,
  errors,
  isRequired = false,
  errorMessage,
  invalidDate,
  onTooltipClick,
  maxDate,
}: any) => {
  return (
    <Controller
      name={name}
      control={control}
      rules={{
        required: errorMessage.required,
        validate: (value) => {
          if (value) {
            return true;
          }
          return false;
        },
      }}
      render={({ field: { onChange, value } }) => (
        <FormControl
          className={className}
          isRequired={isRequired}
          isInvalid={!!errors[name]}
        >
          {title && (
            <HStack>
              <H4 className="form-control-title">{title}</H4>
              <Box
                className="form-control-icon form-input-date-question-mark"
                onClick={onTooltipClick}
              ></Box>
            </HStack>
          )}

          {description && (
            <Text className="form-control-description">{description}</Text>
          )}
          {DescriptionComponent && <DescriptionComponent />}
          {label && <FormLabel>{label}</FormLabel>}
          <DatePicker
            name={name}
            value={value}
            handleChange={onChange}
            required={isRequired}
            labelText={undefined}
            hasError={!!errors[name]}
            placeholder="DD/MM/YYYY"
            numberYearOptions={100}
            maxDate={maxDate}
          />
          {invalidDate && <FormAlert message={errorMessage.notEligibleDate} />}
          <FormErrorMessage>{errors[name]?.message}</FormErrorMessage>
        </FormControl>
      )}
    />
  );
};
