import React, { Fragment, useEffect, useRef, useState } from 'react';
import { CcContainer } from '../CcContainer';
import { H3 } from '@contact/ui-volta';
import { useForm } from 'react-hook-form';
import { Box } from '@chakra-ui/react';
import { FormInput } from '../CcFormComponents/FormInput';
import {
  FORM_CONSTANTS as constants,
  FORM_ERRORS,
} from '../Config/BusiConstants';
import { FormAutoCompleteEmail } from '../CcFormComponents/FormAutoCompleteEmail';
import { FormInputDate } from '../CcFormComponents/FormInputDate';
import './CcForm.scss';
import { FormRadioGroup } from '../CcFormComponents/FormRadioGroup';
import { FormCheckbox } from '../CcFormComponents/FormCheckbox';
import { Button, Loading, Modal } from '@contact/ui-components';
import { DonationAndBankDetails } from '../CcFormComponents/DonationAndBankDetails';
import ReCAPTCHA from 'react-google-recaptcha';
import { useHistory, useLocation } from 'react-router-dom';
import { useCcRefund } from '@contact/data-access-hooks';
import { ErrorModal } from '../ErrorModal';
import { format } from 'date-fns';
import { reCaptchaKey } from '../Config';

export const CcForm = () => {
  const { search } = useLocation();
  const searchParams = new URLSearchParams(search);
  const isCsr = searchParams.get('isCsr') === 'true';
  const history = useHistory();

  const [forceRerenderKey, setForceRerenderKey] = useState(0);

  const {
    watch,
    control,
    resetField,
    getValues,
    formState: { errors, isValid },
  } = useForm({ mode: 'all', shouldUnregister: true });
  const recaptchaRef = useRef<any>(null);
  const [recaptchaValue, setRecaptchaValue] = useState<string | null>(null);

  const [referenceNumberModalIsOpen, setReferenceNumberModalIsOpen] = useState(
    false
  );
  const [genericErrorModalIsOpen, setGenericErrorModalIsOpen] = useState(false);
  const [multiSubErrorModalIsOpen, setMultiSubErrorModalIsOpen] = useState(
    false
  );

  const watchRefrencenumber = watch('refrencenumber', undefined);
  const watchCustomerHistory = watch('customerHistory', undefined);
  const watchCreditHistory = watch('creditHistory', undefined);
  const watchDonationHistory = watch('donationHistory', undefined);
  const watchAccountNumber = watch('accountNumber', undefined);
  const watchBankAccountNumber = watch('bankAccountNumber', undefined);
  const watchBankAccountName = watch('bankAccountName', undefined);
  const watchCustomerTypeHistory = watch('customerTypeHistory', undefined);
  const watchCommunicationHistory = watch('communicationHistory', undefined);
  const hasReceivedCommunication =
    watchCommunicationHistory !== undefined &&
    watchCommunicationHistory === 'true';
  const isBankRefund =
    watchDonationHistory !== undefined && watchDonationHistory === 'refund';
  const isBusinessCustomer =
    watchCustomerTypeHistory !== undefined &&
    watchCustomerTypeHistory === 'sme';

  useEffect(() => {
    resetField('businessName');
    resetField('surName');
    resetField('dob');
    resetField('accountNumber');
    resetField('customerHistory');

    // Some fields won't reset on their own in the UI because ChakraUI isn't reacting to outside changes
    setForceRerenderKey((key) => key + 1);
  }, [watchCustomerTypeHistory]);

  useEffect(() => {
    resetField('creditHistory');
  }, [watchCustomerHistory]);

  useEffect(() => {
    resetField('emailAddress');
    resetField('phoneNumber');
    resetField('refundTerms');
    resetField('donationHistory');
  }, [watchCreditHistory]);

  useEffect(() => {
    resetField('refrencenumber');
    resetField('refundTerms');
    resetField('donationHistory');
    resetField('customerTypeHistory');
  }, [watchCommunicationHistory]);

  useEffect(() => {
    resetField('bankAccountNumber');
    resetField('bankAccountName');
  }, [watchDonationHistory]);

  useEffect(() => {
    setRecaptchaValue(null);
  }, [isValid]);

  const { mutate, data, error, isLoading } = useCcRefund('v1');

  useEffect(() => {
    if (error?.response?.status === 409) {
      setMultiSubErrorModalIsOpen(true);
    } else if (Boolean(error?.response?.status) === true) {
      setGenericErrorModalIsOpen(true);
    }
  }, [error?.response?.status]);

  useEffect(() => {
    if (data?.status === 200) {
      history.push('/success');
    }
  }, [data?.status]);

  const ccFormUi = () => {
    return (
      <div>
        <form
          onSubmit={(e) => {
            e.preventDefault();
            const formValues = getValues();
            const submitValues = {
              reference: formValues.refrencenumber,
              customer_type: formValues.customerTypeHistory,
              channel: isCsr ? 'csr' : 'customer',
              email: formValues.emailAddress,
              ba: formValues.accountNumber,
              last_name: formValues.surName,
              business_name: formValues.businessName,
              dob: formValues.dob
                ? format(formValues.dob, 'yyyy-MM-dd')
                : undefined,
              phone_number: formValues.phoneNumber,
              refund_type: formValues.donationHistory,
              bank_account_name: formValues.bankAccountName,
              bank_account_number: formValues.bankAccountNumber,
              recaptcha_response: recaptchaValue,
            };
            mutate(submitValues);
            setRecaptchaValue(null);
          }}
        >
          <Box className="form-container">
            <Box className="form-header">
              <H3>{constants.subTitle}</H3>
            </Box>

            <Box className="form-body">
              <FormRadioGroup
                name="communicationHistory"
                className="form-control"
                label={constants.communicationHistory.question}
                title=""
                options={constants.communicationHistory.options}
                isRequired
                control={control}
                errors={errors}
                errorMessage=""
                invalidValue={
                  watchCommunicationHistory !== undefined &&
                  watchCommunicationHistory === 'false'
                }
              />
              {hasReceivedCommunication && (
                <>
                  <Box className="form-field">
                    <FormInput
                      name="refrencenumber"
                      className="form-control"
                      label={constants.referenceLabel}
                      isRequired
                      maxLength={6}
                      errors={errors}
                      control={control}
                      errorMessage={JSON.parse(
                        JSON.stringify(FORM_ERRORS.reference)
                      )}
                      showTooltip={true}
                      onTooltipClick={() => setReferenceNumberModalIsOpen(true)}
                    />
                  </Box>
                  <FormCheckbox
                    control={control}
                    name="refundTerms"
                    isRequired
                    label={constants.authorisedRefund}
                    title=""
                    textComponent=""
                    helperText=""
                    className={{
                      text: 'tcText',
                      container: 'tcContainer form-control',
                    }}
                    errors={errors}
                    errorMessage={FORM_ERRORS.refundTerms}
                  />
                  <DonationAndBankDetails
                    errors={errors}
                    control={control}
                    isBankRefund={isBankRefund}
                    watchDonationHistory={watchDonationHistory}
                  />
                </>
              )}
              {!hasReceivedCommunication &&
                watchCommunicationHistory !== undefined && (
                  <>
                    <p className="info-message">
                      {constants.communicationHistory.OptionNoInfo}
                    </p>

                    <FormRadioGroup
                      name="customerTypeHistory"
                      className="form-control"
                      label={constants.customerTypeHistory.question}
                      title=""
                      options={constants.customerTypeHistory.options}
                      isRequired
                      control={control}
                      errors={errors}
                      errorMessage=""
                      invalidValue={
                        watchCustomerTypeHistory !== undefined &&
                        watchCustomerTypeHistory === 'resi'
                      }
                    />
                    {!isBusinessCustomer &&
                      watchCustomerTypeHistory !== undefined && (
                        <>
                          <Box className="form-field">
                            <FormInput
                              name="surName"
                              className="form-control"
                              label={constants.surNameLabel}
                              isRequired
                              maxLength={40}
                              errors={errors}
                              control={control}
                              errorMessage={JSON.parse(
                                JSON.stringify(FORM_ERRORS.surName)
                              )}
                            />
                          </Box>

                          <Box className="form-field">
                            <FormInputDate
                              name="dob"
                              className="form-control"
                              label={constants.dobLabel}
                              title=""
                              isRequired
                              control={control}
                              errors={errors}
                              errorMessage={FORM_ERRORS.dob}
                              onTooltipClick=""
                              invalidDate=""
                              maxDate={new Date()}
                            />
                          </Box>
                        </>
                      )}

                    {isBusinessCustomer && (
                      <Box className="form-field">
                        <FormInput
                          name="businessName"
                          className="form-control"
                          label={constants.businessNameLabel}
                          isRequired
                          maxLength={40}
                          errors={errors}
                          control={control}
                          errorMessage={JSON.parse(
                            JSON.stringify(FORM_ERRORS.business)
                          )}
                        />
                      </Box>
                    )}

                    {watchCustomerTypeHistory !== undefined && (
                      <Fragment key={forceRerenderKey}>
                        <Box className="form-field">
                          <FormInput
                            name="accountNumber"
                            className="form-control"
                            label={constants.accountNumber}
                            maxLength={9}
                            errors={errors}
                            control={control}
                            errorMessage={JSON.parse(
                              JSON.stringify(FORM_ERRORS.accountNumber)
                            )}
                          />
                        </Box>

                        <FormRadioGroup
                          name="customerHistory"
                          className="form-control"
                          label={constants.customerHistory.question}
                          title=""
                          options={constants.customerHistory.options}
                          isRequired
                          control={control}
                          errors={errors}
                          errorMessage=""
                          isFlag={
                            watchCustomerHistory !== undefined &&
                            watchCustomerHistory === 'false'
                          }
                          flagMessage={constants.customerHistory.flag}
                          invalidValue={
                            watchCustomerHistory !== undefined &&
                            watchCustomerHistory === 'false'
                          }
                        />
                      </Fragment>
                    )}
                    {watchCustomerTypeHistory !== undefined &&
                      watchCustomerHistory === 'true' && (
                        <FormRadioGroup
                          name="creditHistory"
                          className="form-control"
                          label={constants.creditHistory.question}
                          title=""
                          options={constants.creditHistory.options}
                          isRequired
                          control={control}
                          errors={errors}
                          errorMessage=""
                          isFlag={
                            watchCreditHistory !== undefined &&
                            watchCreditHistory === 'false'
                          }
                          flagMessage={constants.creditHistory.flag}
                          invalidValue={
                            watchCreditHistory !== undefined &&
                            watchCreditHistory === 'false'
                          }
                        />
                      )}
                    {watchCreditHistory !== undefined &&
                      watchCreditHistory === 'true' && (
                        <>
                          <Box className="form-field">
                            <FormAutoCompleteEmail
                              name="emailAddress"
                              className="form-control form-email"
                              label={constants.emailLabel}
                              isRequired
                              maxLength={241}
                              control={control}
                              errors={errors}
                              errorMessage={JSON.parse(
                                JSON.stringify(FORM_ERRORS.email)
                              )}
                            />
                          </Box>

                          <Box className="form-field">
                            <FormInput
                              name="phoneNumber"
                              className="form-control"
                              label={constants.phoneNumberLabel}
                              isRequired
                              maxLength={30}
                              errors={errors}
                              control={control}
                              errorMessage={JSON.parse(
                                JSON.stringify(FORM_ERRORS.phone)
                              )}
                            />
                          </Box>

                          <FormCheckbox
                            control={control}
                            name="refundTerms"
                            isRequired
                            label={constants.authorisedRefund}
                            title=""
                            textComponent=""
                            helperText=""
                            className={{
                              text: 'tcText',
                              container: 'tcContainer form-control',
                            }}
                            errors={errors}
                            errorMessage={FORM_ERRORS.refundTerms}
                          />

                          <DonationAndBankDetails
                            errors={errors}
                            control={control}
                            isBankRefund={isBankRefund}
                            watchDonationHistory={watchDonationHistory}
                          />
                        </>
                      )}
                  </>
                )}

              {isValid && watchDonationHistory !== undefined ? (
                <ReCAPTCHA
                  sitekey={reCaptchaKey}
                  ref={recaptchaRef}
                  onChange={(e) => {
                    setRecaptchaValue(e);
                  }}
                />
              ) : undefined}

              <Button
                type="submit"
                variant="primary"
                disabled={!isValid || !recaptchaValue || isLoading}
                className={`submitButton ${isValid ? '' : 'disabled'}`}
              >
                {constants.submitButton}
              </Button>
            </Box>
          </Box>
        </form>

        <Modal
          isActive={referenceNumberModalIsOpen}
          title={constants.referenceModalTitle}
          content={<p>{constants.referenceModalContent}</p>}
          handleClose={() => setReferenceNumberModalIsOpen(false)}
          titleClassName="trimester-modal-title"
          contentClassName="trimester-modal-content"
        />

        <ErrorModal
          title={constants.multipleSubmissionErrorModalTitle}
          description={constants.multipleSubmissionErrorModalDescription}
          isOpen={multiSubErrorModalIsOpen}
          handleClose={() => setMultiSubErrorModalIsOpen(false)}
        />
        <ErrorModal
          title={constants.genericErrorModalTitle}
          description={constants.genericErrorModalDescription}
          isOpen={genericErrorModalIsOpen}
          handleClose={() => setGenericErrorModalIsOpen(false)}
        />

        {isLoading && (
          <div className="form-loader">
            <Loading />
          </div>
        )}
      </div>
    );
  };

  return <CcContainer>{ccFormUi()}</CcContainer>;
};
