import React from 'react';
export const FORM_CONSTANTS = {
  title: 'Card processing fee correction',
  subTitle: 'Please complete the details below',
  authorisedRefund:
    'I was previously the Contact account holder or have authority to act on behalf of the account holder.',
  bankAccountNumberLabel: 'Bank Account Number',
  bankAccountNameModalTitle: 'Name of bank account holder',
  bankAccountNameModalContent:
    'The account name as it appears on your bank statement',
  dobLabel: 'Date Of Birth',
  bankAccountTitle:
    'Let us know your bank details. These will be protected for your security and privacy.',
  referenceLabel: 'Reference Number',
  referenceModalTitle: 'Reference Number',
  referenceModalContent:
    'This is the reference number from the communications you received from us. (This is not your Account Number.)',
  bankAccountNameLabel: 'Name of bank account holder',
  emailLabel: 'Email Address',
  accountNumber: 'Account number if known',
  phoneNumberLabel: 'Phone Number',
  surNameLabel: 'Surname',
  businessNameLabel: 'Name of Business',
  customerHistory: {
    question:
      'Were you a customer with us between 1 October 2017 and 26 September 2023?',
    options: [
      { value: 'true', label: 'Yes' },
      { value: 'false', label: 'No' },
    ],
    flag:
      'This account was not affected by the error therefore no refund is due',
  },
  customerTypeHistory: {
    question: 'Were you a residential customer?',
    options: [
      { value: 'residential', label: 'Yes' },
      { value: 'sme', label: 'No' },
    ],
  },
  creditHistory: {
    question: 'Did you pay your bill by credit/debit card during this time?',
    options: [
      { value: 'true', label: 'Yes' },
      { value: 'false', label: 'No' },
    ],
    flag:
      'This account was not affected by the error therefore no refund is due',
  },
  donationHistory: {
    question: `Select what you'd like us to do with your refund.`,
    options: [
      { value: 'donation', label: `Women's Refuge donation` },
      { value: 'refund', label: 'Bank  Deposit' },
    ],
  },
  communicationHistory: {
    question:
      'Have you received communications from us about the card processing fee error?',
    options: [
      { value: 'true', label: 'Yes' },
      { value: 'false', label: 'No' },
    ],
    OptionNoInfo: `Please check your spam/junk mail to make sure you haven't received communication from us.`,
  },
  submitButton: 'Submit',
  genericErrorModalTitle: 'Sorry something went wrong',
  genericErrorModalDescription: 'Please try again later',
  multipleSubmissionErrorModalTitle: '',
  multipleSubmissionErrorModalDescription:
    'Looks like you have responded to us already',
};

export const FORM_ERRORS = {
  refundTerms: 'Please accept refund terms',
  bankAccountName: {
    required: 'Name of bank account holder is required',
    notValid: 'Please use letters only',
  },
  reference: {
    required: 'Reference number required',
    notANumber: 'Reference should have only numbers in input',
    tooShort: 'Reference must have 6 numbers in input',
    notValid: 'Not a valid reference number',
  },
  bankAccountNumber: {
    required: 'Bank account number required',
    notValid: 'Not a valid bank account number',
  },
  firstName: 'First name required',
  lastName: 'Last name required',
  surName: {
    required: 'Surname  is required',
    notValid: 'Please use letters only',
  },
  business: {
    required: 'Name of Business is required',
    notValid: 'Please use letters only',
  },
  accountNumber: {
    notValid: 'Account Number is not valid',
    tooShort: 'Account Number must have 9 numbers in input',
    notANumber: 'Account Number should have only numbers in input',
  },
  email: {
    required: 'Email required',
    notValid: 'Email is not valid',
  },
  phone: {
    required: 'Phone number required',
    notANumber: 'Phone number should have only numbers in input',
    notValid: 'Not a valid phone number',
  },
  dob: 'Date of birth is required',
};

export const SUCCESS_PAGE = {
  title: `Thanks for letting us know how to process your refund and again sorry for the mistake.`,
  title2: `Refunds can take around 30 days to show in your bank account and any donations will be made at the end of March.`,
  title3: `If we are unable to process your refund, we will be in touch.`,
};
