import React, { useState } from 'react';
import { FormRadioGroup } from './FormRadioGroup';
import { Box } from '@chakra-ui/react';
import { FORM_CONSTANTS, FORM_ERRORS } from '../Config/BusiConstants';
import { FormInput } from './FormInput';
import { Modal } from '@contact/ui-components';

export const DonationAndBankDetails = ({
  errors,
  control,
  watchDonationHistory,
  isBankRefund,
}: any) => {
  const [
    bankAccountNumberModalIsOpen,
    setBankAccountNumberModalIsOpen,
  ] = useState(false);

  return (
    <>
      <FormRadioGroup
        name="donationHistory"
        className="form-control"
        label={FORM_CONSTANTS.donationHistory.question}
        title=""
        options={FORM_CONSTANTS.donationHistory.options}
        isRequired
        control={control}
        errors={errors}
        errorMessage=""
        invalidValue={
          watchDonationHistory !== undefined && watchDonationHistory === 'false'
        }
      />
      {isBankRefund && (
        <>
          <Box className="form-field">
            <FormInput
              name="bankAccountNumber"
              className="form-control"
              label={FORM_CONSTANTS.bankAccountNumberLabel}
              title={FORM_CONSTANTS.bankAccountTitle}
              isRequired
              errors={errors}
              control={control}
              errorMessage={JSON.parse(
                JSON.stringify(FORM_ERRORS.bankAccountNumber)
              )}
            />
          </Box>
          <Box className="form-field">
            <FormInput
              name="bankAccountName"
              className="form-control"
              label={FORM_CONSTANTS.bankAccountNameLabel}
              isRequired
              maxLength={40}
              errors={errors}
              control={control}
              errorMessage={JSON.parse(
                JSON.stringify(FORM_ERRORS.bankAccountName)
              )}
              showTooltip={true}
              onTooltipClick={() => setBankAccountNumberModalIsOpen(true)}
            />
          </Box>
        </>
      )}

      <Modal
        isActive={bankAccountNumberModalIsOpen}
        title={FORM_CONSTANTS.bankAccountNameModalTitle}
        content={<p>{FORM_CONSTANTS.bankAccountNameModalContent}</p>}
        handleClose={() => setBankAccountNumberModalIsOpen(false)}
        titleClassName="trimester-modal-title"
        contentClassName="trimester-modal-content"
      />
    </>
  );
};
