import React from 'react';
import Logo from '../Assets/Images/contact-logo-arc.svg';
import { SUCCESS_PAGE as constants } from '../Config/BusiConstants';
import './Success.scss';

export const Success = () => {
  return (
    <div className="form-success_container">
      <img src={Logo} className="form-success_image" />

      <h2 className="form-success_title">{constants.title}</h2>
      <h2 className="form-success_title">{constants.title2}</h2>
      <h2 className="form-success_title">{constants.title3}</h2>
    </div>
  );
};
