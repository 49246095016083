import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { CcForm } from '../CcForm';
import './CcFee.scss';
import { Success } from '../Success';

export function CcFee() {
  return (
    <Switch>
      <Route path="/404-not-found">
        <div>Not Found</div>
      </Route>

      <Route path="/success">
        <Success />
      </Route>

      <Route path="/">
        <CcForm />
      </Route>
    </Switch>
  );
}
