import React from 'react';
import { H1, Box, Flex } from '@contact/ui-volta';
import './CcContainer.scss';
import { FORM_CONSTANTS } from '../Config/BusiConstants';

export interface CcContainerProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  children: React.ReactElement<any>;
}

export const CcContainer: React.FC<CcContainerProps> = ({ children }) => {
  return (
    <Flex className="container">
      <Flex className="container-title">
        <H1>{FORM_CONSTANTS.title}</H1>
      </Flex>

      <Flex className="cc-container">
        <Box className="cc-form" style={{ width: '100%' }}>
          {children}
        </Box>
      </Flex>
    </Flex>
  );
};
