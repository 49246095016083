import React from 'react';
import {
  FormControl,
  FormLabel,
  FormErrorMessage,
  Input,
  H4,
  Text,
  Box,
} from '@contact/ui-volta';
import { QuestionMarkIcon } from '@contact/ui-components';
import { isObject } from 'lodash-es';
import { Controller, Control, FieldErrors } from 'react-hook-form';

export interface ErrorMessage {
  required?: string;
  notANumber?: string;
  notValid?: string;
  tooShort?: string;
}
export interface FormInputProps {
  name: string;
  control: Control;
  label: string;
  title?: string;
  description?: string;
  className?: string;
  errors?: FieldErrors;
  isRequired?: boolean;
  maxLength?: number;
  errorMessage?: string & ErrorMessage;
  translations?: any;
  onTooltipClick?: () => void;
  showTooltip?: boolean;
}

export const FormInput: React.FC<FormInputProps> = ({
  name,
  control,
  label,
  title = undefined,
  description = undefined,
  className = undefined,
  errors,
  isRequired = false,
  maxLength,
  errorMessage,
  onTooltipClick,
  showTooltip = undefined,
}) => {
  return (
    <Controller
      control={control}
      name={name}
      rules={{
        required: isRequired
          ? isObject(errorMessage)
            ? errorMessage.required
            : errorMessage
          : undefined,
        validate: (value) => {
          if (!isRequired && !value) {
            return undefined;
          }

          if (name === 'phoneNumber') {
            const regexp = new RegExp(
              /(^02(0|1|2|3|5|7|8|9)[0-9 ]{6,13}$)|(^0(3|4|6|7|9)[0-9 ]{6,13}$)|(^0(800|508)[0-9 ]{6,14}$)/
            );
            return isNaN(value)
              ? errorMessage.notANumber
              : regexp.test(value?.trim())
              ? undefined
              : errorMessage.notValid;
          } else if (name === 'accountNumber') {
            return isNaN(value)
              ? errorMessage.notANumber
              : value?.length !== 9
              ? errorMessage.tooShort
              : undefined;
          } else if (name === 'refrencenumber') {
            const regexp = new RegExp(/^[a-zA-Z0-9]{6,}$/);
            return regexp.test(value?.trim())
              ? undefined
              : errorMessage.notValid;
          } else if (name === 'bankAccountNumber') {
            const regexp = new RegExp(/(\d{2}-\d{4}-\d{7}-\d{2,3})/);
            return regexp.test(value?.trim())
              ? undefined
              : errorMessage.notValid;
          } else if (
            name === 'bankAccountName' ||
            name === 'surName' ||
            name === 'businessName'
          ) {
            const regexp = new RegExp(/^[\=\-\+\@].*/);
            return regexp.test(value?.trim())
              ? errorMessage.notValid
              : undefined;
          }
        },
      }}
      render={({ field: { name, value, onChange, ref } }) => (
        <FormControl
          className={className}
          isRequired={isRequired}
          isInvalid={!!errors[name]}
        >
          {title && <H4 className="form-control-title">{title}</H4>}
          {description && (
            <Text className="form-control-description">{description}</Text>
          )}
          {label && (
            <Box className="form-control-title form-control-help">
              <FormLabel htmlFor={name}>{label}</FormLabel>
              {showTooltip && (
                <Box className="form-control-icon" onClick={onTooltipClick}>
                  <QuestionMarkIcon
                    margin="4px"
                    boxSize={{
                      base: '20px',
                      lg: '20px',
                    }}
                  />
                </Box>
              )}
            </Box>
          )}
          {/* @ts-ignore: ignore chakra-ui errors for now */}
          <Input
            name={name}
            placeholder={
              name === 'bankAccountNumber' ? '00-0000-0000000-000' : label
            }
            minLength={name === 'bankAccountNumber' ? 16 : undefined}
            maxLength={name === 'bankAccountNumber' ? 19 : maxLength}
            onChange={(e) => {
              if (name === 'bankAccountNumber') {
                onChange({
                  ...e,
                  target: {
                    ...e.target,
                    value: formatBankAccountNumber(e.target.value),
                  },
                });
              } else {
                onChange(e);
              }
            }}
            ref={ref}
            value={value}
          />
          <FormErrorMessage>{errors[name]?.message}</FormErrorMessage>
        </FormControl>
      )}
    />
  );
};

export function formatBankAccountNumber(value: string) {
  return value
    .replace(/[^\d-]/g, '')
    .replace(/[-]?/g, '')
    .replace(/(^\d{2})/g, '$1-')
    .replace(/(^\d{2}-\d{4})/g, '$1-')
    .replace(/(^\d{2}-\d{4}-\d{7})/g, '$1-')
    .replace(/[-]$/g, '');
}
