import React from 'react';
import {
  Checkbox,
  FormControl,
  FormLabel,
  FormErrorMessage,
  FormHelperText,
  H4,
  Text,
} from '@contact/ui-volta';
import { Controller } from 'react-hook-form';

export const FormCheckbox = ({
  control,
  name,
  errors,
  className = undefined,
  label,
  title = undefined,
  description = undefined,
  textComponent: TextComponent = undefined, // react component
  text = undefined,
  helperText = undefined,
  isRequired = false,
  errorMessage,
}: any) => {
  return (
    <Controller
      control={control}
      name={name}
      rules={{ required: errorMessage }}
      render={({
        field: { onChange, value, name, ref },
        fieldState: { invalid },
      }) => (
        <FormControl
          style={{ display: 'flex', flexDirection: 'column' }}
          className={className.container}
          isInvalid={!value}
          isRequired={isRequired}
        >
          {title && <H4 className="form-control-title">{title}</H4>}
          {description && (
            <Text className="form-control-description">{description}</Text>
          )}
          <div style={{ display: 'flex' }}>
            <Checkbox
              onChange={onChange}
              isChecked={value}
              isInvalid={invalid}
              isRequired={isRequired}
            >
              {label && (
                <FormLabel
                  // Have to use inline style to override the css class styling
                  style={{ height: 'auto', marginBottom: 0 }}
                  as="span"
                >
                  {label}
                </FormLabel>
              )}
              {text && <span className={className.text}>{text}</span>}
              {TextComponent && <TextComponent />}
            </Checkbox>
            {helperText && (
              <FormHelperText className="form-helper-text">
                {helperText}
              </FormHelperText>
            )}
          </div>
          {errors[name]?.message && (
            <FormErrorMessage>{errors[name]?.message}</FormErrorMessage>
          )}
        </FormControl>
      )}
    />
  );
};
